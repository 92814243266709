import { jsxs as v, jsx as t, Fragment as X } from "react/jsx-runtime";
import { AlertGroup as Ct, Alert as ve, AlertVariant as me, AlertActionCloseButton as be, Page as It, Modal as je, ModalVariant as kt, Button as V, TextContent as xt, Text as Ce, TextVariants as wt, Spinner as ze, FormHelperText as Ae, HelperText as Te, HelperTextItem as Pe, Popover as St, Icon as Ie, FormGroup as Je, NumberInput as At, ValidatedOptions as B, InputGroup as Ee, InputGroupItem as ke, TextInput as se, Select as oe, MenuToggle as ee, MenuToggleStatus as Oe, SelectList as ce, SelectOption as Re, TextInputGroup as Ze, TextInputGroupMain as Ye, ChipGroup as Qe, Chip as Xe, TextInputGroupUtilities as et, Switch as Tt, TextArea as tt, Title as Pt, Card as ae, CardHeader as Et, CardTitle as z, CardBody as J, Grid as Ot, GridItem as fe, Stack as W, StackItem as j, ClipboardCopy as pe, PageSection as Rt, JumpLinks as Vt, JumpLinksItem as Ft, ButtonVariant as qe, Checkbox as Mt, Radio as Nt, MenuFooter as Dt, Dropdown as Lt, DropdownList as qt, DropdownItem as Be, Brand as Bt, Avatar as _t } from "@patternfly/react-core";
import { createContext as Ve, useContext as Fe, useState as k, useCallback as nt, useEffect as Y, useMemo as L, useRef as de, forwardRef as Kt, useId as Ut, Fragment as rt, Children as lt } from "react";
import { useTranslation as Me } from "react-i18next";
import Ht from "keycloak-js";
import { ExclamationCircleIcon as Gt, HelpIcon as _e, EyeIcon as $t, EyeSlashIcon as Wt, TimesIcon as at, CubeIcon as jt, PaypalIcon as zt, InstagramIcon as Jt, BitbucketIcon as Zt, MicrosoftIcon as Yt, TwitterIcon as Qt, StackOverflowIcon as Xt, OpenshiftIcon as en, LinkedinIcon as tn, GoogleIcon as nn, GitlabIcon as rn, FacebookSquareIcon as ln, GithubIcon as an, CheckIcon as sn, PencilAltIcon as on, MinusCircleIcon as cn, PlusCircleIcon as dn, EllipsisVIcon as un } from "@patternfly/react-icons";
import { useFormContext as ue, Controller as $, useController as Ne, FormProvider as hn, useWatch as mn } from "react-hook-form";
import { get as Q } from "lodash-es";
import { NetworkError as fn } from "@keycloak/keycloak-admin-client";
import { PageHeader as pn, PageHeaderTools as yn, PageHeaderToolsGroup as gn, PageHeaderToolsItem as ye } from "@patternfly/react-core/deprecated";
import re from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as vn } from "@patternfly/react-styles";
import './main.css';const it = Ve(void 0), Ir = () => Fe(it), bn = ({ children: e }) => {
  const [n, r] = k([]), l = (s) => {
    r((d) => d.filter((o) => o.id !== s));
  }, a = (s, d = me.success, o) => {
    r([
      {
        id: Math.random() * 100,
        message: s,
        variant: d,
        description: o
      },
      ...n
    ]);
  }, i = (s) => {
    a(s, me.danger);
  };
  return /* @__PURE__ */ v(it.Provider, { value: { addAlert: a, addError: i }, children: [
    /* @__PURE__ */ t(Ct, { isToast: !0, "data-testid": "alerts", children: n.map(({ id: s, variant: d, message: o, description: u }) => /* @__PURE__ */ t(
      ve,
      {
        isLiveRegion: !0,
        variant: me[d],
        variantLabel: "",
        title: o,
        actionClose: /* @__PURE__ */ t(
          be,
          {
            title: o,
            onClose: () => l(s)
          }
        ),
        timeout: !0,
        onTimeout: () => l(s),
        children: u && /* @__PURE__ */ t("p", { children: u })
      },
      s
    )) }),
    e
  ] });
}, Cn = (e) => {
  const { t: n } = Me(), r = e.error, l = In(r);
  function a() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(It, { children: /* @__PURE__ */ t(
    je,
    {
      variant: kt.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(V, { variant: "primary", onClick: a, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ v(xt, { children: [
        /* @__PURE__ */ t(Ce, { children: n("somethingWentWrongDescription") }),
        l && /* @__PURE__ */ t(Ce, { component: wt.small, children: l })
      ] })
    }
  ) });
};
function In(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function kn(e, n) {
  const r = Ve(n);
  return r.displayName = e, r;
}
function xn(e) {
  return e != null;
}
function wn(e) {
  const n = Fe(e);
  if (xn(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function Sn(e, n, r) {
  const [l, a] = k(
    () => e.getItem(n) ?? r
  ), i = nt((s) => {
    a(s), e.setItem(n, s);
  }, []);
  return Y(() => {
    a(e.getItem(n) ?? r), window.addEventListener("storage", s);
    function s(d) {
      d.storageArea === e && (d.key === null || d.key === n) && a(d.newValue ?? r);
    }
    return () => window.removeEventListener("storage", s);
  }, [e, n]), [l, i];
}
function An(e, n, r) {
  const l = L(
    () => JSON.stringify(r),
    [r]
  ), [a, i] = Sn(
    e,
    n,
    l
  ), s = L(() => JSON.parse(a), [a]), d = nt(
    (o) => i(JSON.stringify(o)),
    []
  );
  return [s, d];
}
const st = kn(
  "HelpContext",
  void 0
), Tn = () => wn(st), Pn = ({ children: e }) => {
  const [n, r] = An(localStorage, "helpEnabled", !0);
  function l() {
    r(!n);
  }
  return /* @__PURE__ */ t(st.Provider, { value: { enabled: n, toggleHelp: l }, children: e });
}, En = () => Ve(void 0);
let xe;
const kr = () => {
  const e = Fe(xe);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, xr = ({
  environment: e,
  children: n
}) => {
  xe = En();
  const r = de(!1), [l, a] = k(!1), [i, s] = k(), d = L(() => {
    const o = new Ht({
      url: e.authServerUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return o.onAuthLogout = () => o.login(), o;
  }, [e]);
  return Y(() => {
    if (r.current)
      return;
    d.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => a(!0)).catch((u) => s(u)), r.current = !0;
  }, [d]), i ? /* @__PURE__ */ t(Cn, { error: i }) : l ? /* @__PURE__ */ t(xe.Provider, { value: { environment: e, keycloak: d }, children: /* @__PURE__ */ t(bn, { children: /* @__PURE__ */ t(Pn, { children: n }) }) }) : /* @__PURE__ */ t(ze, {});
};
function wr(e) {
  const n = document.getElementById("environment");
  let r = {};
  try {
    n?.textContent && (r = JSON.parse(n.textContent));
  } catch {
    console.error("Unable to parse environment variables.");
  }
  return { ...e, ...r };
}
const Sr = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: l,
  isDisabled: a,
  buttonVariant: i,
  buttonTestRole: s,
  onContinue: d,
  component: o = V,
  children: u,
  ...p
}) => {
  const [g, c] = k(!1);
  return /* @__PURE__ */ v(X, { children: [
    /* @__PURE__ */ t(
      o,
      {
        variant: i,
        onClick: () => c(!0),
        isDisabled: a,
        "data-testrole": s,
        children: l
      }
    ),
    /* @__PURE__ */ t(
      je,
      {
        variant: "small",
        ...p,
        title: e,
        isOpen: g,
        onClose: () => c(!1),
        actions: [
          /* @__PURE__ */ t(
            V,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                c(!1), d();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            V,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => c(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: u
      }
    )
  ] });
}, ot = ({ message: e, ...n }) => /* @__PURE__ */ t(Ae, { ...n, children: /* @__PURE__ */ t(Te, { children: /* @__PURE__ */ t(Pe, { icon: /* @__PURE__ */ t(Gt, {}), variant: "error", children: e }) }) }), ct = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: l = !1
}) => {
  const { enabled: a } = Tn();
  return a ? /* @__PURE__ */ t(St, { bodyContent: e, children: /* @__PURE__ */ v(X, { children: [
    !l && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (i) => i.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(Ie, { isInline: r, children: /* @__PURE__ */ t(_e, {}) })
      }
    ),
    l && /* @__PURE__ */ t(Ie, { isInline: r, children: /* @__PURE__ */ t(_e, {}) })
  ] }) }) : null;
}, H = ({
  name: e,
  label: n,
  labelIcon: r,
  error: l,
  children: a,
  ...i
}) => /* @__PURE__ */ v(
  Je,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(ct, { helpText: r, fieldLabelId: e }) : void 0,
    ...i,
    children: [
      a,
      l && /* @__PURE__ */ t(ot, { "data-testid": `${e}-helper`, message: l.message })
    ]
  }
), Ar = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: l,
  ...a
}) => {
  const {
    control: i,
    formState: { errors: s }
  } = ue();
  return /* @__PURE__ */ t(
    H,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: s[e],
      labelIcon: l,
      children: /* @__PURE__ */ t(
        $,
        {
          ...r,
          name: e,
          control: i,
          render: ({ field: d }) => {
            const o = !!r.rules?.required, u = r.rules?.min, p = d.value === 0 ? r.defaultValue : d.value, g = (c) => d.onChange(u ? Math.max(c, Number(u)) : c);
            return /* @__PURE__ */ t(
              At,
              {
                ...a,
                id: e,
                value: p,
                validated: s[e] ? B.error : B.default,
                required: o,
                min: Number(u),
                max: Number(r.rules?.max),
                onPlus: () => g(p + 1),
                onMinus: () => g(p - 1),
                onChange: (c) => {
                  const h = Number(c.currentTarget.value);
                  g(isNaN(h) ? r.defaultValue : h);
                }
              }
            );
          }
        }
      )
    }
  );
}, On = ({
  hasReveal: e = !0,
  innerRef: n,
  isTideIdp: r = !1,
  ...l
}) => {
  const { t: a } = Me(), [i, s] = k(!0);
  return /* @__PURE__ */ v(Ee, { style: { display: r ? "none" : void 0 }, children: [
    /* @__PURE__ */ t(ke, { isFill: !0, children: /* @__PURE__ */ t(
      se,
      {
        ...l,
        type: i ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      V,
      {
        variant: "control",
        "aria-label": a("showPassword"),
        onClick: () => s(!i),
        children: i ? /* @__PURE__ */ t($t, {}) : /* @__PURE__ */ t(Wt, {})
      }
    )
  ] });
}, dt = Kt(
  (e, n) => /* @__PURE__ */ t(On, { ...e, innerRef: n })
);
dt.displayName = "PasswordInput";
const Tr = (e) => {
  const { labelIcon: n, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: i, fieldState: s } = Ne({
    ...e,
    defaultValue: a
  }), d = e.isTideIdp ?? !1;
  return /* @__PURE__ */ v(
    H,
    {
      style: { display: d ? "none" : void 0 },
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          dt,
          {
            isTideIdp: d,
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: s.error ? B.error : B.default,
            isDisabled: e.isDisabled,
            ...r,
            ...i
          }
        ),
        e.helperText && /* @__PURE__ */ t(Ae, { children: /* @__PURE__ */ t(Te, { children: /* @__PURE__ */ t(Pe, { children: e.helperText }) }) })
      ]
    }
  );
}, Rn = ({
  id: e,
  name: n,
  label: r,
  options: l,
  controller: a,
  labelIcon: i,
  ...s
}) => {
  const {
    control: d,
    formState: { errors: o }
  } = ue(), [u, p] = k(!1);
  return /* @__PURE__ */ t(
    H,
    {
      name: n,
      label: r,
      isRequired: !!a.rules?.required,
      error: Q(o, n),
      labelIcon: i,
      children: /* @__PURE__ */ t(
        $,
        {
          ...a,
          name: n,
          control: d,
          render: ({ field: { onChange: g, value: c } }) => /* @__PURE__ */ t(
            oe,
            {
              ...s,
              onClick: () => p(!u),
              onOpenChange: () => p(!1),
              selected: Z(l) ? l.filter(
                (h) => Array.isArray(c) ? c.includes(h.key) : c === h.key
              ).map((h) => h.value) : c,
              toggle: (h) => /* @__PURE__ */ t(
                ee,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: h,
                  onClick: () => p(!u),
                  isExpanded: u,
                  isFullWidth: !0,
                  status: Q(o, n) ? Oe.danger : void 0,
                  "aria-label": "toggle",
                  children: Z(l) ? l.find(
                    (I) => I.key === (Array.isArray(c) ? c[0] : c)
                  )?.value : c
                }
              ),
              onSelect: (h, I) => {
                const y = I?.toString();
                g(Array.isArray(c) ? [y] : y), p(!1);
              },
              isOpen: u,
              children: /* @__PURE__ */ t(ce, { children: l.map((h) => /* @__PURE__ */ t(Re, { value: K(h), children: De(h) ? h : h.value }, K(h))) })
            }
          )
        }
      )
    }
  );
}, le = (e) => De(e) ? e : e.value, Vn = ({
  id: e,
  name: n,
  label: r,
  options: l,
  controller: a,
  labelIcon: i,
  placeholderText: s,
  onFilter: d,
  variant: o,
  ...u
}) => {
  const {
    control: p,
    formState: { errors: g }
  } = ue(), [c, h] = k(!1), [I, y] = k(""), [m, T] = k(0), E = de(), b = l.filter(
    (f) => le(f).toLowerCase().startsWith(I.toLowerCase())
  ), C = L(
    () => b.map((f, w) => /* @__PURE__ */ t(
      Re,
      {
        value: K(f),
        isFocused: m === w,
        children: le(f)
      },
      K(f)
    )),
    [m, b]
  ), F = (f, w) => {
    const x = b[m];
    switch (h(!0), f.key) {
      case "Enter": {
        f.preventDefault(), o !== G.typeaheadMulti ? y(le(x)) : y(""), w.onChange(
          Array.isArray(w.value) ? [...w.value, K(x)] : K(x)
        ), h(!1), T(0);
        break;
      }
      case "Tab":
      case "Escape": {
        h(!1), w.onChange(void 0);
        break;
      }
      case "Backspace": {
        o === G.typeahead && w.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        f.preventDefault();
        let A = 0;
        f.key === "ArrowUp" && (m === 0 ? A = l.length - 1 : A = m - 1), f.key === "ArrowDown" && (m === l.length - 1 ? A = 0 : A = m + 1), T(A);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    H,
    {
      name: n,
      label: r,
      isRequired: !!a.rules?.required,
      error: Q(g, n),
      labelIcon: i,
      children: /* @__PURE__ */ t(
        $,
        {
          ...a,
          name: n,
          control: p,
          render: ({ field: f }) => /* @__PURE__ */ t(
            oe,
            {
              ...u,
              onClick: () => h(!c),
              onOpenChange: () => h(!1),
              selected: Z(l) ? l.filter(
                (w) => Array.isArray(f.value) ? f.value.includes(w.key) : f.value === w.key
              ).map((w) => w.value) : f.value,
              toggle: (w) => /* @__PURE__ */ t(
                ee,
                {
                  ref: w,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => h(!c),
                  isExpanded: c,
                  isFullWidth: !0,
                  status: Q(g, n) ? Oe.danger : void 0,
                  children: /* @__PURE__ */ v(Ze, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      Ye,
                      {
                        placeholder: s,
                        value: o === G.typeahead && f.value ? Z(l) ? l.find(
                          (x) => x.key === (Array.isArray(f.value) ? f.value[0] : f.value)
                        )?.value : f.value : I,
                        onClick: () => h(!c),
                        onChange: (x, A) => {
                          y(A), d?.(A);
                        },
                        onKeyDown: (x) => F(x, f),
                        autoComplete: "off",
                        innerRef: E,
                        role: "combobox",
                        isExpanded: c,
                        "aria-controls": "select-typeahead-listbox",
                        children: o === G.typeaheadMulti && Array.isArray(f.value) && /* @__PURE__ */ t(Qe, { "aria-label": "Current selections", children: f.value.map(
                          (x, A) => /* @__PURE__ */ t(
                            Xe,
                            {
                              onClick: (O) => {
                                O.stopPropagation(), f.onChange(
                                  f.value.filter(
                                    (R) => R !== K(x)
                                  )
                                );
                              },
                              children: Z(l) ? l.find((O) => x === O.key)?.value : le(x)
                            },
                            A
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(et, { children: !!I && /* @__PURE__ */ t(
                      V,
                      {
                        variant: "plain",
                        onClick: () => {
                          f.onChange(void 0), y(""), E?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(at, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (w, x) => {
                w?.stopPropagation();
                const A = x?.toString();
                o === G.typeaheadMulti && Array.isArray(f.value) ? f.value.includes(A) ? f.onChange(
                  f.value.filter((O) => O !== A)
                ) : f.onChange([...f.value, A]) : (f.onChange(Array.isArray(f.value) ? [A] : A), h(!1));
              },
              isOpen: c,
              children: /* @__PURE__ */ t(ce, { children: C })
            }
          )
        }
      )
    }
  );
};
var G = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(G || {});
const Z = (e) => typeof e[0] != "string", De = (e) => typeof e == "string", K = (e) => De(e) ? e : e.key, Fn = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(Rn, { ...n }) : /* @__PURE__ */ t(Vn, { ...n, variant: e }), Pr = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: l,
  ...a
}) => {
  const s = r ?? (n ? "false" : !1), { control: d } = ue();
  return /* @__PURE__ */ t(
    H,
    {
      hasNoPaddingTop: !0,
      name: a.name,
      isRequired: a.rules?.required === !0,
      label: a.label,
      labelIcon: l,
      children: /* @__PURE__ */ t(
        $,
        {
          control: d,
          name: a.name,
          defaultValue: s,
          render: ({ field: { onChange: o, value: u } }) => /* @__PURE__ */ t(
            Tt,
            {
              ...a,
              id: a.name,
              "data-testid": a.name,
              label: e,
              isChecked: n ? u === "true" : u,
              onChange: (p, g) => {
                const c = n ? g.toString() : g;
                a.onChange?.(p, g), o(c);
              }
            }
          )
        }
      )
    }
  );
}, Er = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: l, fieldState: a } = Ne({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    H,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: a.error,
      children: /* @__PURE__ */ t(
        tt,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: a.error ? B.error : B.default,
          isDisabled: e.isDisabled,
          ...l
        }
      )
    }
  );
}, Or = (e) => {
  const { labelIcon: n, ...r } = e, l = !!e.rules?.required, a = e.defaultValue ?? "", { field: i, fieldState: s } = Ne({
    ...e,
    defaultValue: a
  });
  return /* @__PURE__ */ v(
    H,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          se,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e.name,
            validated: s.error ? B.error : B.default,
            isDisabled: e.isDisabled,
            ...r,
            ...i
          }
        ),
        e.helperText && /* @__PURE__ */ t(Ae, { children: /* @__PURE__ */ t(Te, { children: /* @__PURE__ */ t(Pe, { children: e.helperText }) }) })
      ]
    }
  );
}, Mn = tt, Rr = ({ icon: e }) => {
  const n = Nn(e);
  return /* @__PURE__ */ t(Ie, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function Nn(e) {
  switch (e) {
    case "github":
      return an;
    case "facebook":
      return ln;
    case "gitlab":
      return rn;
    case "google":
      return nn;
    case "linkedin":
    case "linkedin-openid-connect":
      return tn;
    case "openshift-v3":
    case "openshift-v4":
      return en;
    case "stackoverflow":
      return Xt;
    case "twitter":
      return Qt;
    case "microsoft":
      return Yt;
    case "bitbucket":
      return Zt;
    case "instagram":
      return Jt;
    case "paypal":
      return zt;
    default:
      return jt;
  }
}
const Dn = "_title_180i0_2", Ln = {
  title: Dn
}, ut = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: l = "xl",
  ...a
}) => /* @__PURE__ */ t(
  Pt,
  {
    headingLevel: r,
    size: l,
    className: Ln.title,
    id: e,
    tabIndex: 0,
    ...a,
    children: n
  }
), qn = ({
  title: e,
  children: n,
  scrollId: r,
  className: l
}) => {
  const a = Ut();
  return /* @__PURE__ */ v(ae, { id: a, className: l, isFlat: !0, children: [
    /* @__PURE__ */ t(Et, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(z, { tabIndex: 0, children: /* @__PURE__ */ t(ut, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(J, { className: "kc-form-panel__body", children: n })
  ] });
}, Bn = (e) => {
  const { title: n, children: r, scrollId: l, ...a } = e;
  return /* @__PURE__ */ t("section", { ...a, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ v(X, { children: [
    /* @__PURE__ */ t(ut, { id: l, title: n }),
    r
  ] }) });
}, _n = "_panel_1cdve_1", Kn = "_sticky_1cdve_5", Ke = {
  panel: _n,
  sticky: Kn
}, Un = "kc-main-content-page-container", Ue = (e) => e.replace(/\s+/g, "-"), Hn = ({
  label: e,
  sections: n,
  borders: r = !1,
  submitVendorCallback: l,
  tideIdp: a = !1,
  tideIdpDetails: i,
  newLicenseRequest: s,
  savePayerKeyCallback: d,
  rotateKeyCallback: o,
  isButtonDisabled: u = !0,
  ...p
}) => {
  const [g, c] = k(!1), [h, I] = k(!1), [y, m] = k(""), [T, E] = k(""), [b, C] = k(""), [F, f] = k(!1), [w, x] = k(!1), [A, O] = k(""), [R, M] = k(""), ne = L(
    () => n.filter(({ isHidden: S }) => !S),
    [n]
  );
  Y(() => {
    const S = JSON.stringify(
      {
        gVRK: i?.gVRK || "",
        vendorId: i?.vendorId || "",
        payerPublic: i?.payerPublic || ""
      },
      null,
      2
    ), P = JSON.stringify(
      {
        gVRK: s?.tempgVRK || "",
        vendorId: s?.tempVendorId || ""
      },
      null,
      2
    );
    O(S), M(P);
  }, [i]);
  const pt = async (S) => {
    try {
      x(!0), b === void 0 || b === "" ? (I(!1), E("Payer Public Key has not been set"), m("Provide the details to Ziva to receive your Payer Public Key to continue"), c(!0)) : (await S(), c(!1), I(!0), E("Settings Secured"), m("Settings have been signed successfully")), x(!1);
    } catch (P) {
      x(!1), I(!1);
      const N = ge(P);
      E("Error Securing Signatures"), m(N ?? ""), c(!0);
    }
  };
  Y(() => {
    a && (i?.payerPublic !== void 0 && i?.payerPublic !== "" ? (C(i.payerPublic), f(!0)) : f(!1));
  }, []);
  const yt = () => {
    f(!1);
  }, gt = async (S) => {
    try {
      await S(b), c(!1), f(!0);
    } catch (P) {
      I(!1);
      const N = ge(P);
      E("Error Adding Payer Public Key"), m(N ?? ""), c(!0);
    }
  }, vt = async (S) => {
    try {
      x(!0), await S(), c(!1), I(!0), E("New License Request Generated"), m("Provide these new details to Ziva"), x(!1);
    } catch (P) {
      x(!1), I(!1);
      const N = ge(P);
      E("Error Generating License Request"), m(N ?? ""), c(!0);
    }
  }, bt = ({ title: S, hasSuccess: P, hasError: N, message: Le }) => /* @__PURE__ */ v(X, { children: [
    N && /* @__PURE__ */ t(
      ve,
      {
        style: { margin: "0px 10px 10px 10px" },
        variant: "danger",
        isInline: !0,
        title: S,
        actionClose: /* @__PURE__ */ t(be, { onClose: () => c(!1) }),
        children: Le
      }
    ),
    P && /* @__PURE__ */ t(
      ve,
      {
        style: { margin: "0px 10px 10px 10px" },
        variant: "success",
        isInline: !0,
        title: S,
        actionClose: /* @__PURE__ */ t(be, { onClose: () => I(!1) }),
        children: Le
      }
    )
  ] });
  return /* @__PURE__ */ v(Ot, { hasGutter: !0, ...p, children: [
    /* @__PURE__ */ t(fe, { md: 8, sm: 12, children: ne.map(({ title: S, panel: P }) => {
      const N = Ue(S.toLowerCase());
      return /* @__PURE__ */ t(rt, { children: r ? /* @__PURE__ */ t(
        qn,
        {
          scrollId: N,
          title: S,
          className: Ke.panel,
          children: P
        }
      ) : /* @__PURE__ */ t(Bn, { scrollId: N, title: S, children: P }) }, S);
    }) }),
    /* @__PURE__ */ v(fe, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: [
      a === !0 && /* @__PURE__ */ t(fe, { children: /* @__PURE__ */ v(ae, { style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: [
        /* @__PURE__ */ t(z, { component: "h1", style: { textAlign: "center", fontSize: "1.25rem" }, children: "Vendor Sign Up" }),
        /* @__PURE__ */ t(bt, { title: T, hasSuccess: h, hasError: g, message: y }),
        /* @__PURE__ */ v(ae, { style: { margin: "0px 10px 10px 10px" }, children: [
          /* @__PURE__ */ t(z, { style: { textAlign: "center" }, children: "Active License" }),
          /* @__PURE__ */ t(J, { children: /* @__PURE__ */ t(W, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ t(j, { style: { width: "100%" }, children: i?.vvkId !== "" ? /* @__PURE__ */ t(pe, { isReadOnly: !0, isCode: !0, children: A }) : /* @__PURE__ */ t("p", { children: " No Active License, request a new license and provide the details to Ziva" }) }) }) })
        ] }),
        /* @__PURE__ */ v(ae, { style: { margin: "0px 10px 10px 10px" }, children: [
          /* @__PURE__ */ t(z, { style: { textAlign: "center" }, children: "Provide these details to Ziva" }),
          /* @__PURE__ */ t(J, { children: /* @__PURE__ */ t(W, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ t(j, { style: { width: "100%" }, children: /* @__PURE__ */ t(pe, { isReadOnly: !0, isCode: !0, children: R }) }) }) }),
          /* @__PURE__ */ t(z, { style: { textAlign: "center" }, children: "Payer Public Key" }),
          /* @__PURE__ */ t(J, { children: /* @__PURE__ */ t(W, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ v(j, { style: { width: "100%", display: "flex", alignItems: "center" }, children: [
            /* @__PURE__ */ t(
              pe,
              {
                isReadOnly: F,
                onChange: (S, P) => C(P ?? ""),
                "aria-label": "Payer Public Key",
                style: {
                  backgroundColor: F ? "#f0f0f0" : "white",
                  color: F ? "#6a6e73" : "black",
                  flex: "1"
                },
                children: b
              }
            ),
            /* @__PURE__ */ t("div", { style: { display: "flex" }, children: F ? /* @__PURE__ */ t(
              V,
              {
                variant: "control",
                onClick: yt,
                "aria-label": "Edit",
                children: /* @__PURE__ */ t(on, {})
              }
            ) : /* @__PURE__ */ t(
              V,
              {
                variant: "control",
                onClick: () => gt(d ?? (() => {
                })),
                "aria-label": "Save",
                children: /* @__PURE__ */ t(sn, {})
              }
            ) })
          ] }) }) })
        ] }),
        /* @__PURE__ */ t(J, { children: w ? /* @__PURE__ */ t(W, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ t(ze, { size: "xl" }) }) : /* @__PURE__ */ v(W, { hasGutter: !0, style: { alignItems: "center" }, children: [
          /* @__PURE__ */ t(j, { children: /* @__PURE__ */ t(V, { onClick: () => vt(o ?? (() => {
          })), children: "New License Request" }) }),
          /* @__PURE__ */ t(j, { children: /* @__PURE__ */ t(V, { isDisabled: u, onClick: () => pt(l ?? (() => {
          })), children: "Secure Settings" }) })
        ] }) })
      ] }) }),
      /* @__PURE__ */ t(Rt, { className: Ke.sticky, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ t(
        Vt,
        {
          isVertical: !0,
          scrollableSelector: `#${Un}`,
          label: e,
          offset: 100,
          children: ne.map(({ title: S }) => {
            const P = Ue(S.toLowerCase());
            return (
              // note that JumpLinks currently does not work with spaces in the href
              /* @__PURE__ */ t(
                Ft,
                {
                  href: `#${P}`,
                  "data-testid": `jump-link-${P}`,
                  children: S
                },
                S
              )
            );
          })
        }
      ) })
    ] })
  ] });
};
function ge(e) {
  if (typeof e == "string")
    return e;
  if (e instanceof fn)
    return Gn(e);
  if (e instanceof Error)
    return e.message;
  throw new Error("Unable to determine error message.");
}
function Gn({ responseData: e }) {
  const n = e;
  for (const r of ["error_description", "errorMessage", "error"]) {
    const l = n[r];
    if (typeof l == "string")
      return l;
  }
}
const $n = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, Vr = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: l = !1,
  children: a,
  ...i
}) => /* @__PURE__ */ t(
  V,
  {
    variant: "primary",
    isDisabled: e && !$n(e, l, r) || n,
    ...i,
    type: "submit",
    children: a
  }
), Wn = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, jn = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: l
}) => {
  const a = L(
    () => r.map((i) => ({
      key: i,
      value: e(`locale_${i}`, Wn(i) ?? i)
    })).sort((i, s) => i.value.localeCompare(s.value, l)),
    [r, l, e]
  );
  return a.length ? /* @__PURE__ */ t(hn, { ...n, children: /* @__PURE__ */ t(
    Fn,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: a,
      variant: a.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, ht = (e) => e?.includes("${"), mt = (e) => e.substring(2, e.length - 1), U = (e, n, r) => (ht(n) ? e(mt(n)) : n) || r, we = (e, n) => U(e, n.displayName, n.name), zn = ["username", "firstName", "lastName", "email"], ft = (e) => e && zn.includes(e), _ = (e) => `${ft(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Fr = (e) => e.replaceAll(".", "🍺"), Mr = (e) => e.replaceAll("🍺", ".");
function Nr(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((l) => {
    const a = Object.assign(
      {},
      l.params?.map((i) => r(ht(i.toString()) ? mt(i) : i))
    );
    n(_(l.field), {
      message: r(l.errorMessage, {
        ...a,
        defaultValue: l.errorMessage || l.field
      }),
      type: "server"
    });
  });
}
function he({
  required: e,
  validators: n
}) {
  return e || Jn(n);
}
function Jn(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function Dr(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return He(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(He);
}
function He(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const te = ({
  t: e,
  form: n,
  attribute: r,
  renderer: l,
  children: a
}) => {
  const i = U(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: s }
  } = n, d = l?.(r), o = Q(s, _(r.name));
  return /* @__PURE__ */ v(
    Je,
    {
      label: we(e, r) || "",
      fieldId: r.name,
      isRequired: he(r),
      labelIcon: i ? /* @__PURE__ */ t(ct, { helpText: i, fieldLabelId: r.name }) : void 0,
      children: [
        d ? /* @__PURE__ */ v(Ee, { children: [
          a,
          d
        ] }) : a,
        o && /* @__PURE__ */ t(
          ot,
          {
            "data-testid": `${r.name}-helper`,
            message: o.message
          }
        )
      ]
    },
    r.name
  );
}, Zn = ({
  t: e,
  form: n,
  attribute: r,
  renderer: l,
  ...a
}) => /* @__PURE__ */ t(te, { t: e, form: n, attribute: r, renderer: l, children: /* @__PURE__ */ t(
  Yn,
  {
    t: e,
    form: n,
    "aria-label": we(e, r),
    name: _(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: we(e, r)
    }),
    ...a
  }
) }), Yn = ({
  t: e,
  name: n,
  inputType: r,
  form: l,
  addButtonLabel: a,
  isDisabled: i = !1,
  defaultValue: s,
  id: d,
  ...o
}) => {
  const { register: u, setValue: p, control: g } = l, c = mn({
    name: n,
    control: g,
    defaultValue: s || ""
  }), h = L(() => Array.isArray(c) && c.length !== 0 ? c : s || [""], [c]), I = (b) => {
    T([...h.slice(0, b), ...h.slice(b + 1)]);
  }, y = () => {
    T([...h, ""]);
  }, m = (b, C) => {
    T([...h.slice(0, b), C, ...h.slice(b + 1)]);
  }, T = (b) => {
    const C = b.flatMap((F) => F);
    p(n, C, {
      shouldDirty: !0
    });
  }, E = r.startsWith("html") ? r.substring(6) : "text";
  return Y(() => {
    u(n);
  }, [u]), /* @__PURE__ */ t("div", { id: d, children: h.map((b, C) => /* @__PURE__ */ v(rt, { children: [
    /* @__PURE__ */ v(Ee, { children: [
      /* @__PURE__ */ t(ke, { isFill: !0, children: /* @__PURE__ */ t(
        se,
        {
          "data-testid": n + C,
          onChange: (F, f) => m(C, f),
          name: `${n}.${C}.value`,
          value: b,
          isDisabled: i,
          type: E,
          ...o
        }
      ) }),
      /* @__PURE__ */ t(ke, { children: /* @__PURE__ */ t(
        V,
        {
          "data-testid": "remove" + C,
          variant: qe.link,
          onClick: () => I(C),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: h.length === 1 || i,
          children: /* @__PURE__ */ t(cn, {})
        }
      ) })
    ] }),
    C === h.length - 1 && /* @__PURE__ */ v(
      V,
      {
        variant: qe.link,
        onClick: y,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !b || i,
        children: [
          /* @__PURE__ */ t(dn, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, C)) });
}, Ge = (e) => {
  const { form: n, inputType: r, attribute: l } = e, a = he(l), i = r.startsWith("multiselect"), s = i ? Mt : Nt, d = l.validators?.options?.options || [], o = l.annotations?.inputOptionLabels || {};
  return /* @__PURE__ */ t(te, { ...e, children: /* @__PURE__ */ t(
    $,
    {
      name: _(l.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: u }) => /* @__PURE__ */ t(X, { children: d.map((p) => /* @__PURE__ */ t(
        s,
        {
          id: p,
          "data-testid": p,
          label: U(e.t, o[p], p),
          value: p,
          isChecked: u.value.includes(p),
          onChange: () => {
            i ? u.value.includes(p) ? u.onChange(
              u.value.filter((g) => g !== p)
            ) : u.onChange([...u.value, p]) : u.onChange([p]);
          },
          readOnly: l.readOnly,
          isRequired: a
        },
        p
      )) })
    }
  ) });
}, Qn = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: l,
  isOpen: a,
  menuAppendTo: i,
  direction: s,
  width: d,
  maxHeight: o,
  toggleIcon: u,
  className: p,
  children: g,
  ...c
}) => {
  const [h, I] = k(!1), y = de(), m = () => {
    I(!h), n(!h);
  }, T = () => i === "parent" && y.current?.parentElement || "inline", E = lt.toArray(
    g
  );
  return /* @__PURE__ */ t(
    oe,
    {
      ref: y,
      maxMenuHeight: ie(o),
      isScrollable: !0,
      popperProps: {
        appendTo: T(),
        direction: s,
        width: ie(d)
      },
      ...c,
      onClick: m,
      onOpenChange: () => I(!1),
      selected: l,
      onSelect: (b, C) => {
        r?.(C || ""), m();
      },
      toggle: (b) => /* @__PURE__ */ t(
        ee,
        {
          id: e,
          ref: b,
          className: p,
          onClick: m,
          isExpanded: a,
          "aria-label": c["aria-label"],
          icon: u,
          isFullWidth: !0,
          children: E.find((C) => C.props.value === l)?.props.children || l || c["aria-label"]
        }
      ),
      isOpen: a,
      children: /* @__PURE__ */ t(ce, { children: g })
    }
  );
}, Xn = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: l,
  variant: a,
  validated: i,
  placeholderText: s,
  maxHeight: d,
  width: o,
  toggleIcon: u,
  direction: p,
  selections: g,
  typeAheadAriaLabel: c,
  chipGroupComponent: h,
  chipGroupProps: I,
  footer: y,
  children: m,
  ...T
}) => {
  const [E, b] = k(""), [C, F] = k(0), f = de(), w = lt.toArray(
    m
  ), x = () => {
    r?.(!T.isOpen);
  }, A = (O) => {
    const R = w[C];
    switch (r?.(!0), O.key) {
      case "Enter": {
        O.preventDefault(), a !== q.typeaheadMulti ? b(R.props.value) : b(""), n?.(R.props.value), r?.(!1), F(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        a === q.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        O.preventDefault();
        let M = 0;
        O.key === "ArrowUp" && (C === 0 ? M = w.length - 1 : M = C - 1), O.key === "ArrowDown" && (C === w.length - 1 ? M = 0 : M = C + 1), F(M);
        break;
      }
    }
  };
  return /* @__PURE__ */ v(
    oe,
    {
      ...T,
      onClick: x,
      onOpenChange: () => r?.(!1),
      onSelect: (O, R) => n?.(R || ""),
      maxMenuHeight: ie(d),
      popperProps: { direction: p, width: ie(o) },
      toggle: (O) => /* @__PURE__ */ t(
        ee,
        {
          ref: O,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: u,
          isExpanded: T.isOpen,
          isFullWidth: !0,
          status: i === "error" ? Oe.danger : void 0,
          children: /* @__PURE__ */ v(Ze, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              Ye,
              {
                placeholder: s,
                value: a === q.typeahead && g ? g : E,
                onClick: x,
                onChange: (R, M) => {
                  b(M), l?.(M);
                },
                onKeyDown: (R) => A(R),
                autoComplete: "off",
                innerRef: f,
                role: "combobox",
                isExpanded: T.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": c,
                children: a === q.typeaheadMulti && Array.isArray(g) && (h || /* @__PURE__ */ t(Qe, { ...I, children: g.map((R, M) => /* @__PURE__ */ t(
                  Xe,
                  {
                    onClick: (ne) => {
                      ne.stopPropagation(), n?.(R);
                    },
                    children: R
                  },
                  M
                )) }))
              }
            ),
            /* @__PURE__ */ t(et, { children: !!E && /* @__PURE__ */ t(
              V,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), b(""), l?.(""), f?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(at, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(ce, { children: m }),
        y && /* @__PURE__ */ t(Dt, { children: y })
      ]
    }
  );
};
var q = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(q || {});
const ie = (e) => typeof e == "number" ? e + "px" : e, er = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(Qn, { ...n }) : /* @__PURE__ */ t(Xn, { ...n, variant: e }), $e = (e) => {
  const { t: n, form: r, inputType: l, attribute: a } = e, [i, s] = k(!1), [d, o] = k(""), u = l === "multiselect", p = (y, m) => {
    u ? m.value.includes(y) ? m.onChange(m.value.filter((T) => T !== y)) : Array.isArray(m.value) ? m.onChange([...m.value, y]) : m.onChange([y]) : m.onChange(y === m.value ? "" : y);
  }, g = a.validators?.options?.options || [], c = a.annotations?.inputOptionLabels || {}, h = (y) => U(e.t, c[y], y), I = (y) => g.filter(
    (m) => h(m).toLowerCase().includes(d.toLowerCase())
  ).map((m) => /* @__PURE__ */ t(
    Re,
    {
      selected: y === m,
      value: m,
      children: h(m)
    },
    m
  ));
  return /* @__PURE__ */ t(te, { ...e, children: /* @__PURE__ */ t(
    $,
    {
      name: _(a.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: y }) => /* @__PURE__ */ t(
        er,
        {
          toggleId: a.name,
          onToggle: (m) => s(m),
          onClear: () => p("", y),
          onSelect: (m) => {
            const T = m.toString();
            p(T, y), Array.isArray(y.value) || s(!1);
          },
          selections: u && Array.isArray(y.value) ? y.value : h(y.value),
          variant: u ? q.typeaheadMulti : g.length >= 10 ? q.typeahead : q.single,
          "aria-label": n("selectOne"),
          isOpen: i,
          isDisabled: a.readOnly,
          onFilter: (m) => (o(m), I(y.value)),
          children: I(y.value)
        }
      )
    }
  ) });
}, tr = (e) => {
  const { form: n, attribute: r } = e, l = he(r);
  return /* @__PURE__ */ t(te, { ...e, children: /* @__PURE__ */ t(
    Mn,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(_(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: l
    }
  ) });
}, D = (e) => {
  const { form: n, inputType: r, attribute: l } = e, a = he(l), i = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(te, { ...e, children: /* @__PURE__ */ t(
    se,
    {
      id: l.name,
      "data-testid": l.name,
      type: i,
      placeholder: U(
        e.t,
        l.annotations?.inputTypePlaceholder
      ),
      readOnly: l.readOnly,
      isRequired: a,
      ...n.register(_(l.name))
    }
  ) });
}, Se = {
  text: D,
  textarea: tr,
  select: $e,
  "select-radiobuttons": Ge,
  multiselect: $e,
  "multiselect-checkboxes": Ge,
  "html5-email": D,
  "html5-tel": D,
  "html5-url": D,
  "html5-number": D,
  "html5-range": D,
  "html5-datetime-local": D,
  "html5-date": D,
  "html5-month": D,
  "html5-time": D,
  "multi-input": Zn
}, Lr = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: l,
  currentLocale: a,
  hideReadOnly: i = !1,
  renderer: s
}) => {
  const d = L(() => {
    if (!r.attributes)
      return [];
    const o = i ? r.attributes.filter(({ readOnly: u }) => !u) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((u) => ({
      group: u,
      attributes: o.filter(
        (p) => p.group === u.name
      )
    }));
  }, [
    i,
    r.groups,
    r.attributes
  ]);
  return d.length === 0 ? null : /* @__PURE__ */ t(
    Hn,
    {
      label: e("jumpToSection"),
      sections: d.filter((o) => o.attributes.length > 0).map(({ group: o, attributes: u }) => ({
        title: U(e, o.displayHeader, o.name) || e("general"),
        panel: /* @__PURE__ */ v("div", { className: "pf-v5-c-form", children: [
          o.displayDescription && /* @__PURE__ */ t(Ce, { className: "pf-v5-u-pb-lg", children: U(e, o.displayDescription, "") }),
          u.map((p) => /* @__PURE__ */ t(
            nr,
            {
              t: e,
              form: n,
              supportedLocales: l,
              currentLocale: a,
              renderer: s,
              attribute: p
            },
            p.name
          ))
        ] })
      }))
    }
  );
}, nr = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: l,
  currentLocale: a,
  attribute: i
}) => {
  const s = n.watch(
    _(i.name)
  ), d = L(() => lr(i), [i]), o = i.multivalued || ir(s) && i.annotations?.inputType === void 0 ? Se["multi-input"] : Se[d];
  return i.name === "locale" ? /* @__PURE__ */ t(
    jn,
    {
      form: n,
      supportedLocales: l,
      currentLocale: a,
      t: e,
      attribute: i
    }
  ) : /* @__PURE__ */ t(
    o,
    {
      t: e,
      form: n,
      inputType: d,
      attribute: i,
      renderer: r
    }
  );
}, rr = "text";
function lr(e) {
  if (ft(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return ar(n) ? n : rr;
}
const ar = (e) => typeof e == "string" && e in Se, ir = (e) => Array.isArray(e) && e.length > 1, sr = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ v(
  "svg",
  {
    className: vn(
      re.avatar,
      re.modifiers[r],
      n === "light" && re.modifiers.light,
      n === "dark" && re.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ v("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), We = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...l
}) => {
  const [a, i] = k(!1);
  return /* @__PURE__ */ t(
    Lt,
    {
      ...l,
      popperProps: {
        position: "right"
      },
      toggle: (s) => /* @__PURE__ */ t(
        ee,
        {
          "data-testid": `${l["data-testid"]}-toggle`,
          ref: s,
          onClick: () => i(!a),
          isExpanded: a,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(un, {}) : n
        }
      ),
      isOpen: a,
      children: /* @__PURE__ */ t(qt, { children: r })
    }
  );
};
function or(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, l = e.family_name, a = e.preferred_username;
  return r && l ? n("fullName", { givenName: r, familyName: l }) : r || l || a || n("unknownUser");
}
const qr = ({
  keycloak: e,
  brand: { href: n, ...r },
  avatar: l,
  features: {
    hasLogout: a = !0,
    hasManageAccount: i = !0,
    hasUsername: s = !0
  } = {},
  kebabDropdownItems: d,
  dropdownItems: o = [],
  toolbarItems: u,
  ...p
}) => {
  const { t: g } = Me(), c = [];
  i && c.push(
    /* @__PURE__ */ t(
      Be,
      {
        onClick: () => e.accountManagement(),
        children: g("manageAccount")
      },
      "manageAccount"
    )
  ), a && c.push(
    /* @__PURE__ */ t(Be, { onClick: () => e.logout(), children: g("signOut") }, "signOut")
  );
  const h = e.idTokenParsed?.picture;
  return /* @__PURE__ */ t(
    pn,
    {
      ...p,
      logo: /* @__PURE__ */ t(Bt, { ...r }),
      logoProps: { href: n },
      headerTools: /* @__PURE__ */ v(yn, { children: [
        /* @__PURE__ */ v(gn, { children: [
          /* @__PURE__ */ t(
            ye,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ t(
                We,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...d || o,
                    c
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ t(ye, { children: u }),
          /* @__PURE__ */ t(
            ye,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ t(
                We,
                {
                  "data-testid": "options",
                  dropDownItems: [...o, c],
                  title: s ? or(e.idTokenParsed, g) : void 0
                }
              )
            }
          )
        ] }),
        h || l?.src ? /* @__PURE__ */ t(_t, { src: h, alt: g("avatar"), ...l }) : /* @__PURE__ */ t(sr, { ...l })
      ] })
    }
  );
};
export {
  bn as AlertProvider,
  Sr as ContinueCancelModal,
  Cn as ErrorPage,
  ot as FormErrorText,
  qn as FormPanel,
  Vr as FormSubmitButton,
  Pn as Help,
  ct as HelpItem,
  Rr as IconMapper,
  qr as KeycloakMasthead,
  xr as KeycloakProvider,
  er as KeycloakSelect,
  Mn as KeycloakTextArea,
  Ar as NumberControl,
  Tr as PasswordControl,
  dt as PasswordInput,
  Hn as ScrollForm,
  Fn as SelectControl,
  G as SelectVariant,
  Pr as SwitchControl,
  Er as TextAreaControl,
  Or as TextControl,
  Lr as UserProfileFields,
  Fr as beerify,
  kn as createNamedContext,
  Mr as debeerify,
  wr as getInjectedEnvironment,
  xn as isDefined,
  Dr as isUserProfileError,
  U as label,
  Un as mainPageContentId,
  Nr as setUserProfileServerError,
  Ir as useAlerts,
  kr as useEnvironment,
  Tn as useHelp,
  wn as useRequiredContext,
  An as useStoredState
};
